.redirect-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none !important;

  &.adb-link {
    color: #4cb2ca;
  }

  &.bo-link {
    color: #5b69cc;
  }

  &.ft-link {
    color: #003f7d;
  }
}
